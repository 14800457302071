import React, { FC, Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import ListItem, { ListItemProps } from './ListItem';
import { states, countries } from '../default/Default';
import { Textbox, TextboxProps, TextboxType } from './Textbox';
import { useForm } from 'react-hook-form';
import { Text } from '..';
import { GridContainer, LayoutColumn } from './Layout';

export interface AutoCompleteProps {
    searchOptions: TextboxProps;
    items: ListItemProps[];
    type?: AutoCompleteType;
    onSearch?: (q: string, e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onAdd?: (q: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onRemove?: (tagIndex: number, tag: ListItemProps) => void;
    isLoading?: boolean;
    isBusy?: boolean;
    required?: boolean;
    limit?: number;
    icon?: string;
    statePicker?: boolean;
    countryPicker?: boolean;
    disabled?: boolean;
    onChange?: (event: any, q: string) => void;
    onClear?: () => void;
    value?: any;
    width?: number;
    clearOnSelect?: boolean;
    clearOnBlur?: boolean;
    creatableHelperText?: string;
    fullWidthTags?: boolean;
    showItemsOnFocus?: boolean;
    name: string;
    additionalFilterOn?: AutoCompleteFilterOn;
    register?: any; // https://react-hook-form.com/api#register
    errors?: any; // https://react-hook-form.com/api#errors
    setValue?: any; // https://react-hook-form.com/api#setValue
}

export enum AutoCompleteFilterOn {
    secondaryText = 'secondaryText',
    tertiaryText = 'tertiaryText',
    quaternaryText = 'quaternaryText',
}

export type AutoCompleteType = 'default' | 'tags' | 'creatable' | 'creatable-tags' | 'dropdown';

interface AutoCompleteListItemProps extends ListItemProps {
    decoratedText: string;
    selected: boolean;
}

interface AutoCompleteState {
    keyword: string;
    data: AutoCompleteListItemProps[];
    selectedIndex: number;
    selectedItem: AutoCompleteListItemProps;
    selectedTags: ListItemProps[];
    focused: boolean;
}

export const AutoComplete: FC<AutoCompleteProps> = (props) => {
    let [containerUniqueID] = useState<string>('divAutoCompleteContainer-' + uuidv4());
    let [resultUniqueID] = useState<string>('divAutoCompleteResult-' + uuidv4());
    let [keyword, setKeyword] = useState<string>('');
    let [data, setData] = useState<AutoCompleteListItemProps[]>([]);
    let [selectedItem, setSelectedItem] = useState<AutoCompleteListItemProps>({
        text: '',
        decoratedText: '',
        selected: false,
    });
    let [selectedTags, setSelectedTags] = useState<ListItemProps[]>(props.value ? props.value : []);
    let [focused, setFocused] = useState<boolean>(false);
    let [touched, setTouched] = useState<boolean>(false);
    let [showData, setShowdata] = useState<boolean>(false);

    let { register, errors, setValue } = useForm({
        mode: 'onChange',
    });

    if (props.register) register = props.register;

    if (props.errors) errors = props.errors;

    if (props.setValue) setValue = props.setValue;

    useEffect(() => {
        // setValue(props.name, props.type === "tags" || props.type === "creatable-tags" ? "" : props.value);
        // setKeyword(props.value ? props.value : "")
        if (props.value && props.type === 'default') {
            setSelectedItem(typeof props.value === 'object' ? props.value : { text: props.value, selected: true });
            setKeyword(typeof props.value === 'object' ? props.value.text : props.value);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setData(props.onSearch ? mapToAutoCompleteListItem(props.items) : filterData(''));
    }, [props.items]);

    useEffect(() => {
        if (props.type === 'dropdown') setData(mapToAutoCompleteListItem(props.items));
    }, [props.type]);

    useEffect(() => {
        if (props.type === 'tags' || props.type === 'creatable-tags') {
            setSelectedTags(props.value || []);
        }
    }, [props.value]);

    const onTagAddition = (tag: ListItemProps) => {
        const tags = [...selectedTags, tag];
        setSelectedTags(tags);
        setSelectedItem({ text: props.value ? props.value : '', decoratedText: '', selected: true });
        setData(filterData('', focused));
        setKeyword('');

        $('#' + resultUniqueID).hide();

        props.onChange && props.onChange(tags, keyword);
    };

    const onTagDelete = (i: number, tag: ListItemProps) => {
        const tags = selectedTags.slice(0);
        tags.splice(i, 1);
        setSelectedTags(tags);

        props.onRemove && props.onRemove(i, tag);
        props.onChange && props.onChange(tags, keyword);
    };

    const onKeyUp = (element: React.KeyboardEvent<HTMLInputElement>): void => {
        props.onKeyUp && props.onKeyUp(element);
        $('#' + resultUniqueID).show();
        var keyword = element.currentTarget.value;

        if (props.onSearch) {
            props.onSearch(keyword, element);
            setKeyword(keyword);
        } else {
            setKeyword(keyword);
            setData(filterData(keyword, focused));
            setSelectedItem({ text: keyword, decoratedText: '', selected: false });
        }
    };

    const onFocus = (): void => {
        var containerElement = $('#' + containerUniqueID)[0];
        hideOnClickOutside(containerElement);

        setFocused(true);
        setData(props.onSearch ? mapToAutoCompleteListItem(props.items) : filterData(keyword, true));
        setTouched(true);

        $('#' + resultUniqueID).show();
    };

    const onBlur = (e: any): void => {
        if (e.relatedTarget && e.relatedTarget.classList) {
            let classList = e.relatedTarget.classList.value;

            if (classList.includes('list-group-item')) props.onBlur && props.onBlur(e);
            else {
                if (props.clearOnBlur && !selectedItem.selected) onTextboxEndIconClick();

                props.onBlur && props.onBlur(e);
            }
        } else {
			if (props.clearOnBlur && !selectedItem.selected) onTextboxEndIconClick();

            props.onBlur && props.onBlur(e);
        }
    };

    const onClick = (index: number, item: AutoCompleteListItemProps, event: any): boolean => {
        if (props.type !== 'tags') props.onChange && props.onChange(item, keyword);

        if (props.clearOnSelect) {
            onTextboxEndIconClick();
            return true;
        }

        item.selected = true;
        setKeyword(item.text);
        setSelectedItem(item);
        setFocused(false);
        setTouched(false);

        if (props.type === 'dropdown') {
            setShowdata(false);
        } else {
            setData(filterData(''));
        }

        return true;
    };

    const filterData = (keyword: string, focused?: boolean): AutoCompleteListItemProps[] => {
        if (props.statePicker) {
            var statesData: ListItemProps[] = states.map((itm: any) => {
                return {
                    text: itm.name,
                    secondaryText: itm.abbreviation,
                };
            });

            let data = statesData.filter((item: ListItemProps) => {
                if (keyword.length > 0 || focused) {
                    if ((item.text && item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) || (item.secondaryText && item.secondaryText.toLowerCase().indexOf(keyword.toLowerCase()) > -1)) {
                        return true;
                    }
                } else {
                    return false;
                }

                return false;
            });

            return mapToAutoCompleteListItem(data, keyword);
        } else if (props.countryPicker) {
            var countryData: ListItemProps[] = countries.map((itm: any) => {
                return {
                    text: itm.name,
                    secondaryText: itm.code,
                };
            });

            let data = countryData.filter((item: ListItemProps) => {
                if (keyword.length > 0 || focused) {
                    if ((item.text && item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) || (item.secondaryText && item.secondaryText.toLowerCase().indexOf(keyword.toLowerCase()) > -1)) {
                        return true;
                    }
                } else {
                    return false;
                }

                return false;
            });

            return mapToAutoCompleteListItem(data, keyword);
        } else {
            let data = props.items.filter((item: ListItemProps) => {
                if (keyword.length > 0 || focused) {
                    if (props.additionalFilterOn) {
                        if (
                            (item.text && item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
                            (item[props.additionalFilterOn] && item[props.additionalFilterOn].toLowerCase().indexOf(keyword.toLowerCase()) > -1)
                        )
                            return true;
                    } else {
                        if (item.text && item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                            return true;
                        }
                    }
                } else {
                    return false;
                }

                return false;
            });

            return mapToAutoCompleteListItem(data, keyword);
        }
    };

    const mapToAutoCompleteListItem = (data: ListItemProps[], keyword?: string) => {
        var newData = data.map((item: ListItemProps) => {
            var newItem: AutoCompleteListItemProps = {
                ...item,
                decoratedText: keyword ? renderText(keyword, item.text) : item.text,
                selected: false,
            };

            return newItem;
        });

        let limit = props.limit === undefined ? 10 : props.limit;

        if (limit > newData.length) limit = newData.length;

        return newData.slice(0, limit);
    };

    const onTextboxEndIconClick = (): void => {
        setKeyword('');
        setData(filterData(''));
        setSelectedItem({ text: '', decoratedText: '', selected: false });
    };

    const hideOnClickOutside = (element: HTMLElement) => {
        const outsideClickListener = (event: any) => {
            var target = $(event.target);
            if (!target.closest(element).length && $(element).is(':visible')) {
                $('#' + resultUniqueID).hide();
                removeClickListener();
            }
        };

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        };

        document.addEventListener('click', outsideClickListener);
    };

    const renderText = (keyword: string, text: string): string => {
        var resultText = text;
        var result: string[] = [];

        while (true) {
            var idx: number = resultText.toLowerCase().indexOf(keyword.toLowerCase());

            if (idx < 0) {
                result.push(resultText);
                break;
            }

            if (idx === 0) result.push('<b>' + resultText.substr(0, keyword.length) + '</b>');
            else {
                result.push(resultText.substr(0, idx));
                result.push('<b>' + resultText.substr(idx, keyword.length) + '</b>');
            }

            resultText = resultText.substr(idx + keyword.length);
        }

        return result.join('');
    };

    const getValue = () => {
        if (props.onSearch) return keyword;

        if (props.type === 'default') return selectedItem?.text;

        return keyword;
    };

    let searchOptionsObject = props.searchOptions;
    searchOptionsObject.endIcon = '';
    //add some sort of %similarity ?

    //Change icon to plus for creatable
    let showAddCreateableTag = false;
    if (props.type === 'creatable' || props.type === 'creatable-tags') {
        showAddCreateableTag = data.length === 0 || data.filter((row) => row.text.toLowerCase().trim() === keyword.toLowerCase().trim()).length === 0;
        if (showAddCreateableTag) searchOptionsObject.endIcon = 'plus';
        else searchOptionsObject.endIcon = '';
    }
    //Change icon to chevron-down for autocomplete dropdown
    if (props.type === 'dropdown') {
        searchOptionsObject.endIcon = 'chevron-down';
    }

    return (
        //make sure this class is well taken care of
        <div aria-busy={props.isLoading ?? false} className={props.isLoading ? 'auto-complete loading compact' : 'auto-complete compact'} id={containerUniqueID}>
            <Textbox
                compact={true}
                isBusy={props.isBusy}
                isLoading={props.isLoading}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyUp(e)}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => onFocus()}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur(e)}
                onEndIconClick={(e: React.MouseEvent) => {
                    if (props.type === 'creatable' && data.length === 0 && keyword) props.onAdd && props.onAdd(keyword);
                    else if (props.type === 'creatable-tags' && data.length === 0 && keyword) {
                        let tag: ListItemProps = { text: keyword };
                        let autoCompleteItem: AutoCompleteListItemProps = {
                            decoratedText: '',
                            text: keyword,
                            selected: false,
                        };
                        data.push(autoCompleteItem);
                        onTagAddition(tag);
                        // props.onAdd && props.onAdd(keyword);
                    } else if (props.type === 'dropdown') {
                        setData(props.onSearch ? mapToAutoCompleteListItem(props.items) : filterData(keyword, true));
                        if (touched) {
                            setTouched(false);
                            setShowdata(false);
                        } else {
                            setTouched(true);
                            setShowdata(true);
                        }
                    } else {
                        props.onChange && props.onChange(e, keyword);
                    }
                }}
                disabled={props.disabled}
                value={getValue()}
                {...searchOptionsObject}
                properties={{ 'aria-label': 'Search', autoComplete: 'off' }}
                type={TextboxType.IconStart | TextboxType.IconEnd}
                clearable
                onClear={() => {
                    onTextboxEndIconClick();
                    props.onClear && props.onClear();
                }}
                startIcon={props.icon}
                endIconRequired={(props.type === 'creatable' || props.type === 'creatable-tags') && showAddCreateableTag ? true : false}
                register={register}
                required={props.type === 'tags' || props.type === 'creatable-tags' ? (!!selectedTags.length ? false : props.required) : props.required}
                setValue={setValue}
                width={props.width}
                errors={(props.type === 'tags' || props.type === 'creatable-tags') && !!selectedTags.length ? {} : errors}
                name={props.name}
            />
            {keyword && !props.isBusy && !props.isLoading && data.length === 0 && (props.type === 'creatable' || props.type === 'creatable-tags') && props.creatableHelperText && (
                <GridContainer>
                    <LayoutColumn colSize={12} alignment='center'>
                        <Text padded content={props.creatableHelperText} />
                    </LayoutColumn>
                </GridContainer>
            )}
            {(showData ? showData : (keyword || (props.showItemsOnFocus && data.length > 0)) && touched && !props.isBusy) && (
                <div role="list" className={`list-group list-group-compact`} id={resultUniqueID}>
                    {data.length === 0 ? (
                        <Fragment>
                            {props.type !== 'creatable' && props.type !== 'creatable-tags' && (
                                <ListItem
                                    text={'No matches found.'}
                                    onClick={() => {
                                        onTextboxEndIconClick();
                                        setShowdata(false);
                                    }}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {props.type === 'default'
                                ? data.map((item: AutoCompleteListItemProps, number: number) => {
                                      return (
                                          <ListItem
                                              id={`${item.decoratedText}-${number}`}
                                              {...item}
                                              text={item.decoratedText}
                                              filter={keyword}
                                              key={number}
                                              onClick={(event: React.MouseEvent) => {
                                                  onClick(number, item, event);
                                                  item.onClick && item.onClick(event, item);
                                                  props.type === 'tags' && onTagAddition(item);
                                                  props.type === 'creatable-tags' && onTagAddition(item);
                                              }}
                                          />
                                      );
                                  })
                                : data
                                      .filter((item: AutoCompleteListItemProps) => selectedTags.filter((tag: ListItemProps) => tag.text === item.text).length === 0)
                                      .map((item: AutoCompleteListItemProps, number: number) => {
                                          return (
                                              <ListItem
                                                  {...item}
                                                  id={`${item.decoratedText}-${number}`}
                                                  text={item.decoratedText}
                                                  filter={keyword}
                                                  key={number}
                                                  onClick={(event: React.MouseEvent) => {
                                                      onClick(number, item, event);
                                                      item.onClick && item.onClick(event, item);
                                                      props.type === 'tags' && onTagAddition(item);
                                                      props.type === 'creatable-tags' && onTagAddition(item);
                                                  }}
                                              />
                                          );
                                      })}
                        </Fragment>
                    )}
                </div>
            )}
            {(props.type === 'tags' || props.type === 'creatable-tags') && (
                <ul className={`tags ${props.fullWidthTags && 'fullwidth'}`}>
                    {
                        //Style tags
                        selectedTags.map((tag: ListItemProps, index: number) => {
                            return (
                                <li key={`li-item-${index}`} onClick={(e) => tag.onClick && tag.onClick(e, tag)}>
                                    {tag.text} <i onClick={() => onTagDelete(index, tag)} className='fal fa-times' />
                                </li>
                            );
                        })
                    }
                </ul>
            )}
        </div>
    );
};

AutoComplete.defaultProps = {
    type: 'default',
    icon: 'search',
    isLoading: false,
    isBusy: false,
};
