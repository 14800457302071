import React, { FC, useState, useEffect, Fragment } from "react";
import $ from "jquery";
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from "cleave.js/react";
import { useForm } from "react-hook-form";
import { ValidationContainer } from "./Form/ValidationContainer";
import Button, { ButtonProps } from "./Button/Button";

export interface ValidationProps {
	message: string;
	type: ValidationType;
	active: boolean;
}

export enum ValidationType {
	Required,
	Format,
	System,
}

export interface TextboxProps {
	name: string;
	placeholderText?: string;
	helperText?: string; //this is not replacing placeholder text. It is expanding it
	startIcon?: string;
	endIcon?: string;
	type?: TextboxType;
	masked?: MaskedType;
	validation?: ValidationProps;
	startIconAccessibilityText?: string;
	endIconAccessibilityText?: string;
	endIconRequired?: boolean; // If set true, this will make the button more pronounced.
	isBusy?: boolean;
	isLoading?: boolean;
	required?: boolean; //A Boolean which, if true, indicates that the input must have a value before the form can be submitted.
	maxLength?: number; //The maximum length of the value to accept for this input.
	minLength?: number; //The minimum length of the value to accept for this input.
	max?: number; //The maximum value to accept for this input.
	numericOnly?: boolean;
	min?: number; //The minimum value to accept for this input.
	pattern?: any; //The regex pattern for the input.
	onActionClick?: any;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
	onEndIconClick?: (event: any, q: string) => void;
	onKeyUp?: (event: any) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onClear?: (event: React.FocusEvent<HTMLInputElement>) => void;
	properties?: { [key: string]: string };
	value?: string;
	compact?: boolean;
	clearable?: boolean;
	width?: number;
	autoFocus?: boolean;
	disabled?: boolean;
	inputMask?: any;
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
	buttonProps?: ButtonProps;
	ariaLabel?: string;
  ariaLabelledBy?: string;
}

export type MaskedType = "normal" | "tel" | "costObject" | "zip" | "ddmm" | "mmdd" | "mmddyyyy" | "ddmmyyyy" | "yyyymmdd" | "custom";

export enum TextboxType {
	Normal = 1,
	IconStart = 2,
	IconEnd = 4,
	Underlined = 8,
}

export const Textbox: FC<TextboxProps> = (props) => {
	let { register, errors, setValue } = useForm({
		mode: "onChange",
	});

	if (props.register) register = props.register;

	if (props.errors) errors = props.errors;

	if (props.setValue) setValue = props.setValue;

	const [keyword, setKeyword] = useState(props.value ? props.value : "");

	useEffect(() => {
		//@ts-ignore
		setValue(props.name, props.value);
		setKeyword(props.value ? props.value : "");
	}, [props.value]);

	const isTypeAssigned = (textboxType: TextboxType): boolean => {
		//@ts-ignore
		if ((props.type & textboxType) === textboxType) return true;
		else return false;
	};

	const onKeyUp = (element: HTMLInputElement & EventTarget): void => {
		var keyword = element.value;
		setKeyword(keyword);
	};

	const onClearAction = (event: any): void => {
		if (props.clearable) {
			$(event.currentTarget).parent().next("input").val("");
			setKeyword("");

			if (props.onClear) return props.onClear(event);
		}
	};

	const onEndIconClick = (event: React.MouseEvent): void => {
		if (props.onEndIconClick) props.onEndIconClick(event, keyword);
	};

	const getClasses = (): string => {
		var classes: string[] = [];

		if (props.compact) classes.push("compact");

		if (isTypeAssigned(TextboxType.IconStart)) classes.push("icon-start");

		if (isTypeAssigned(TextboxType.Normal)) classes.push("active");

		if (isTypeAssigned(TextboxType.Underlined)) classes.push("underlined");

		if (isTypeAssigned(TextboxType.IconEnd)) classes.push("icon-end");

		if (props.clearable) classes.push("icon-end");

		if (props.masked === "tel") {
			classes.push("tel");
		}

		if (props.masked === "costObject") {
			classes.push("cost-object");
		}

		if (props.masked === "zip") {
			classes.push("zip");
		}

		if (props.isBusy) classes.push("busy-indicator");

		if (props.endIconRequired) classes.push("isRequired");

		return classes.join(" ");
	};

	const renderInput = () => {
		if (props.numericOnly)
			return (
				<Cleave
					name={props.name}
					id={props.name}
					disabled={props.disabled}
					value={keyword}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					options={{ numericOnly: true }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
				/>
			);

		if (props.masked === "tel")
			return (
				<Cleave
					name={props.name}
					id={props.name}
					disabled={props.disabled}
					value={keyword}
					aria-required={props.required}
					className={`form-control`}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					options={{ numericOnly: true, delimiters: ["(", ") ", "-"], blocks: [0, 3, 3, 4] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
				/>
			);

		if (props.masked === "custom" && props.inputMask !== undefined)
			return (
				<Cleave
					name={props.name}
					options={props.inputMask}
					id={props.name}
					disabled={props.disabled}
					value={keyword}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
				/>
			);

		if (props.masked === "costObject")
			return (
				<Cleave
					name={props.name}
					id={props.name}
					disabled={props.disabled}
					value={keyword}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					options={{ numericOnly: true, blocks: [7] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
				/>
			);

		if (props.masked === "mmdd")
			return (
				<Cleave
					options={{ date: true, delimiter: "/", datePattern: ["m", "d"] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					value={keyword}
					name={props.name}
					id={props.name}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText || "Select a day"}
				/>
			);

		if (props.masked === "ddmm")
			return (
				<Cleave
					options={{ date: true, delimiter: "/", datePattern: ["d", "m"] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					name={props.name}
					id={props.name}
					value={keyword}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText || "Select a day"}
				/>
			);

		if (props.masked === "ddmmyyyy")
			return (
				<Cleave
					options={{ date: true, delimiter: "/", datePattern: ["d", "m", "Y"] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					value={keyword}
					name={props.name}
					id={props.name}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText || "Select a day"}
				/>
			);

		if (props.masked === "mmddyyyy")
			return (
				<Cleave
					options={{ date: true, delimiter: "/", datePattern: ["m", "d", "Y"] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					value={keyword}
					name={props.name}
					id={props.name}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText || "Select a day"}
				/>
			);

		if (props.masked === "yyyymmdd")
			return (
				<Cleave
					options={{ date: true, delimiter: "/", datePattern: ["Y", "m", "d"] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					value={keyword}
					name={props.name}
					id={props.name}
					className={`form-control`}
					aria-required={props.required}
					placeholder={props.placeholderText || "Select a day"}
				/>
			);

		if (props.masked === "zip")
			return (
				<Cleave
					name={props.name}
					id={props.name}
					disabled={props.disabled}
					value={keyword}
					className={`form-control`}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					options={{ numericOnly: true, blocks: [5] }}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					aria-required={props.required}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
				/>
			);

		return (
			<Fragment>
				<input
					required={props.required}
					id={props.name}
					aria-required={props.required}
					aria-labelledby={props.ariaLabelledBy}
					aria-label={props.ariaLabel}
          aria-busy={props.isLoading || props.isBusy}
					name={props.name}
					ref={register({
						required: props.required,
						maxLength: props.maxLength,
						minLength: props.minLength,
						min: props.min,
						max: props.max,
						pattern: props.pattern,
					})}
					onChange={(event: any) => {
						props.onChange && props.onChange(event);
						onKeyUp(event.currentTarget);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => props.onFocus && props.onFocus(event)}
					onBlur={(event: React.FocusEvent<HTMLInputElement>) => props.onBlur && props.onBlur(event)}
					onKeyUp={(event: any) => props.onKeyUp && props.onKeyUp(event)}
					className={`form-control`}
					placeholder={props.placeholderText ? props.placeholderText : ""}
					disabled={props.disabled}
					autoFocus={props.autoFocus}
					type="text"
					{...props.properties}
				/>
				{props.helperText && (
					<div className="textbox-helper-text">
						<i>{props.helperText}</i>
					</div>
				)}
			</Fragment>
		);
	};

	let endIcon = props.endIcon;
	let endIconAccessibilityText = props.endIconAccessibilityText;

	if (props.isLoading) {
		return (
			<div className={`text loading ${props.compact && "compact"}`}>
				<div className="skeleton-loader" />
			</div>
		);
	}

	return (
		<div aria-busy={props.isLoading ?? false} className={`text ${getClasses()}`} style={{ width: props.width + "%" }}>
			{props.startIcon && props.startIcon.length > 0 && (
				<span aria-label={`${props.startIconAccessibilityText}${props.isLoading ? " currently loading" : ""}`}>
					<i className={`start-icon fal fa-${props.startIcon}`} />
				</span>
			)}
			<div style={{ overflow: "hidden" }}>
				{keyword && props.clearable && !props.isBusy && (
					<button className={`icon-end`} aria-label={"clear"} type="button" onClick={(e) => onClearAction(e)}>
						<i className={`icon-end fas fa-times`} />
					</button>
				)}
				{(keyword || endIcon === "chevron-down") && endIcon && endIcon.length > 0 && !props.isBusy && (
					<button type="button" className={`${getClasses()}`} aria-label={endIconAccessibilityText} onClick={(event: React.MouseEvent) => onEndIconClick(event)}>
						<i className={`icon-end fas fa-${endIcon}`} />
					</button>
				)}
				{props.buttonProps && (
					<Button
						{...props.buttonProps}
						properties={{
							style: {
								position: "absolute",
								right: 5,
								maxHeight: "2rem",
								top: 3,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							},
						}}
					/>
				)}
			</div>
			{!keyword && props.required && !props.isBusy && <span className="text-required-dot" />}
			{props.isBusy && (
				<div className="loader">
					<div className="pulsate-loading" />
				</div>
			)}
			<ValidationContainer name={props.name} validation={props.validation} errors={errors} value={props.value} keyword={keyword} />

			{renderInput()}
		</div>
	);
};

Textbox.defaultProps = {
	masked: "normal",
	name: "",
	maxLength: 500,
	minLength: 0,
	max: 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999, //Hardfix for weird validation issue
	min: 0,
	type: TextboxType.Normal,
	endIconRequired: false,
	compact: true,
	width: 100,
};
