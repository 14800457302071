import React, { FC, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ValidationContainer } from "./Form/ValidationContainer";

export interface CheckboxProps {
	label: string;
	properties?: { [key: string]: string };
	checked?: boolean;
	onClick?: (checked: boolean) => void;
	name: string;
	required?: boolean; //A Boolean which, if true, indicates that the input must have a value before the form can be submitted.
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
}

const Checkbox: FC<CheckboxProps> = (props) => {
	const [checked, setChecked] = useState<boolean>(props.checked ?? false);

	useEffect(() => {
		if (props.checked != null) setChecked(props.checked);
	}, [props.checked]);

	let { register, errors } = useForm({
		mode: "onChange",
	});

	if (props.register) register = props.register;

	if (props.errors) errors = props.errors;

	const getClasses = (): string => {
		var classes: string[] = [];

		if (checked) classes.push("checked");

		return classes.join(" ");
	};

	return (
		<div className={`form-check ${getClasses()}`}>
			<input
				ref={register({
					required: props.required
				})}
				onClick={() => {
					setChecked(!checked);
					props.onClick && props.onClick(!checked);
				}}
				defaultChecked={checked}
				className="form-check-input"
				type="checkbox"
				name={props.name}
				id={props.name}
				{...props.properties}
			/>
			<label className="form-check-label" htmlFor={props.name} style={{ marginTop: '3px' }}>
				<b>{props.label}</b>
			</label>
		</div>
	);
};

export default Checkbox;

// export default class Checkbox extends React.Component<CheckboxProps, CheckboxState>
// {
//     constructor(props: CheckboxProps)
//     {
//         super(props)

//         this.state = {
//             checked: this.props.checked ? this.props.checked : false
//         }
//     }

//     componentDidUpdate(prevProps: CheckboxProps)
//     {
//         if (this.props.checked !== prevProps.checked)
//         {
//             this.setState({
//                 checked: this.props.checked ? this.props.checked : false
//             })
//         }
//     }

//     getClasses(): string
//     {
//         var classes: string[] = [];

//         if (this.state.checked)
//             classes.push("checked");

//         return classes.join(' ');
//     }

//     render(): React.ReactNode
//     {
//         return (
//             <div className={`form-check ${this.getClasses()}`}>
//                 <input ref={this.props.register({ type: 'checkbox' })} onClick={() => { this.setState({ checked: !this.state.checked }); this.props.onClick(!this.state.checked) }} defaultChecked={this.state.checked} className="form-check-input" type="checkbox" name={this.props.name} id={this.props.name} {...this.props.properties} />
//                 <label className="form-check-label" htmlFor={this.props.name}><b>{this.props.label}</b></label>
//             </div>
//         );
//     }
// }
