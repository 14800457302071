import React from "react";
import Text, { TextType } from "../Text";
import { ButtonBarProps } from "../Button";
import Icon from "../Icon";

export interface TemplateMasterProps {
    master: any;
    masterTitle: string;
    detail: any;
    detailTitle: string;
    navigation: any;
    utilNavigation?: ButtonBarProps;
    mobileView: MobileView;
    startState: boolean; // true = component will transition in, false = component will not transition in
    onBack: () => void;
    compact?: boolean;
    fullWidth?: boolean;
}

export enum MobileView {
    None,
    Master,
    Detail
}

interface TemplateMasterState {
    maximized: boolean
}

export default class TemplateMaster extends React.Component<TemplateMasterProps, TemplateMasterState>
{
    constructor(props: TemplateMasterProps) {
        super(props);

        this.state = {
            maximized: false
        }
    }

    public static defaultProps =
        {
            mobileView: MobileView.Master,
            compact: false,
            fullWidth: false
        };



    render(): React.ReactNode {
        return (
            <div className={`master-detail ${this.state.maximized ? 'maximized' : ''}`} >
                <div className={`row no-gutters  h-100 mh-100`}>
                    <div className={`master ${(this.props.compact ? ' col-lg-3' : 'col-lg-4 ')} overflow-auto align-self-stretch h-100 mh-100 ${(this.props.startState) && 'slide-in-right'} ${(this.props.mobileView === MobileView.Master) && 'infront'}`}>
                        <div className={`row no-gutters align-self-stretch h-100 mh-100`}>
                            <div className={`col-12 overflow-auto align-self-stretch  mh-100`} role="navigation" aria-label="Secondary.">
                                {this.props.master}
                            </div>
                        </div>
                    </div>

                    <div className={`detail ${(this.props.compact ? ' col-lg-9' : 'col-lg-8 ')} overflow-auto align-self-stretch  ${(this.props.startState) && 'slide-in-right-slower'} ${(this.props.mobileView === MobileView.Detail) && 'infront'}`}>
                        <div className="header" role="navigation" aria-label="Tertiary.">
                            <div className="mobile-navigation">
                                <a className="" onClick={() => this.props.onBack && this.props.onBack()} ><Icon type="solid" icon="chevron-left" /></a>
                            </div>
                            {(this.props.fullWidth) &&
                                <div className="desktop-navigation">
                                    <a onClick={() => this.setState({ maximized: !this.state.maximized })}><Icon type="solid" icon="arrows-h" /></a>
                                </div>
                            }
                            {this.props.detailTitle && <Text type={TextType.Heading4} content={this.props.detailTitle} padded={false} />}
                            <div className={`${(this.props.fullWidth ? 'container-fluid' : 'container-sm')}`}>

                                {this.props.navigation}
                                {/* OLD CODE <NavBar type={NavBarTypes.Tabs} tabs={this.props.navigation} utilNav={this.props.utilNavigation} /> 
                                   */}

                            </div>
                        </div>

                        <div className="detail-contents" role="region" aria-label="detail-content">
                            <div className={`${(this.props.fullWidth ? 'container-fluid' : 'container-sm')}`}>
                                <div className={` row no-gutters align-self-stretch h-100 mh-100`}>
                                    <div className={`col-12 py-4`}>{this.props.detail}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
