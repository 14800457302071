import React from "react";
import Text, { TextType } from "../Text";
import { NavBarTypes } from "../Nav";
import { TextboxProps, TextboxType, Textbox } from '../Textbox';
import { AutoCompleteProps } from "../AutoComplete";
import { AutoComplete } from "../..";

export interface BasicLayoutProps
{
    content: any;
    contentTitle: string;
    startState: boolean; // true = component will transition in, false = component will not transition in
    className?: string;
    navigation?: any;
    search?: TextboxProps;
    fullWidth?: boolean;
    autoComplete?: AutoCompleteProps;
    insideNav?: any;
}

export default class BasicLayout extends React.Component<BasicLayoutProps>
{


    public static defaultProps =
        {
            fullWidth: false
        };


    getClasses(): string
    {
        var classes: string[] = [];

        if(this.props.className) classes.push(this.props.className)
        if (this.props.search) classes.push('search-included')

        return classes.join(' ');
    }

    render(): React.ReactNode
    {

        /* const searchProps: TextboxProps =
        {
            startIcon: 'search',
            startIconAccessibilityText: 'search',
            placeholderText: 'Search your list',
            type: TextboxType.IconStart,
            name: "search"
        } */

        return (
            <div className={`basic-layout row no-gutters ${(this.getClasses())} ${(this.props.startState) && 'slide-in-bottom-whole'}`}>

                <div className={`layout-wrapper col-lg-12 overflow-auto align-self-stretch  `}>
                    <div className="header" role="navigation" aria-label="tertiary">
                        <div className="row">
                            <div className="col-12 pt-3 pl-5 mt-5">
                                <Text type={TextType.Heading1} content={this.props.contentTitle} padded={false} />
                            </div>
                            <div className="col-lg-4 col-sm-6 mx-auto text-center">
                                {(this.props.search) && <div className="search"><Textbox type={TextboxType.IconStart | TextboxType.IconEnd} {...this.props.search} endIcon="search" /></div>}
                                {(this.props.autoComplete) && <div className="search"><AutoComplete {...this.props.autoComplete} /></div>}
                            </div>
                        </div>
                    </div>

                    <div className={`content-area ${(this.props.startState) && 'slide-in-bottom'}`} role="region" aria-label="detail-content">
                        <div className={`navbar-lift ${(this.props.navigation?.props.type === NavBarTypes.IconText) && 'navbar-lift-more'}`}>
                            {(this.props.navigation) && this.props.navigation}
                        </div>
                        {(this.props.insideNav) && <div className={`navbar-inside`}>
                            <div className={`col-12 py-4`}>{this.props.insideNav}</div>
                        </div>}
                        <div className={`${(this.props.fullWidth ? 'container-fluid' : 'container-sm')}`}>

                            <div className={` row no-gutters align-self-stretch h-100 mh-100`}>
                                <div className={`col-12 py-4`}>{this.props.content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
